/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import 'quill/dist/quill.snow.css';
// @tailwind base;
// @tailwind components;
// @tailwind utilities;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
html, body { height: 100%; }
body { margin: 0; font-family: Inter, sans-serif; }

:root {
--mdc-dialog-container-shape: 12px;
}

/* Set heroicon's stroke width */
.heroicon-sw-2 path {
    stroke-width: 2 !important;
}
.heroicon-sw-1\.75 path {
    stroke-width: 1.75 !important;
}
.heroicon-sw-1\.5 path {
    stroke-width: 1.5 !important;
}
.heroicon-sw-1\.2 path {
    stroke-width: 1.2 !important;
}

.success-toaster {
    --mdc-snackbar-container-color: #F6FEF9 !important;
    --mdc-snackbar-supporting-text-color: #067647 !important;
    --mat-mdc-snack-bar-button-color: white !important;
    white-space: pre-wrap
}

.error-toaster {
    --mdc-snackbar-container-color: #FFFBFA !important;
    --mdc-snackbar-supporting-text-color: #B42318 !important;
    --mat-mdc-snack-bar-button-color: white !important;
    white-space: pre-wrap
}

.thumb {
    animation: custom-dialog-enter 1s ease;
}

@keyframes custom-dialog-enter {
    from {
      transform: scale(0) rotate(360deg);
    }
  
    to {
      transform: none;
    }
  }

.cdk-overlay-dark-backdrop {
    background-color: #fff;
    opacity: 0.8 !important;
}

:focus-visible {
    outline: 0;
}

:host ::ng-deep .ql-toolbar {
    border: 1px solid #D0D5DD;
    border-bottom: 1px solid #EAECF0 !important;
    background-color: #F9FAFB !important;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

:host ::ng-deep .ql-toolbar>*>button>svg>* {
    stroke: #98A2B3;
}

:host ::ng-deep .ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
    fill: #98A2B3;
    stroke: none;
}

:host ::ng-deep .ql-container {
    color: #98A2B3;
    border: 1px solid #D0D5DD;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

:host ::ng-deep .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
    background-color: #6941C6 !important;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
    background-color: #6941C6!important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0.03 !important;
    background-color: #6941C6!important;
  }

.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #6941C6;
  }

.ql-editor {
    font-family: Inter, sans-serif;
    color: #101828;
    p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

    }

    h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 0.5rem;
    }

    h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 0.5rem;
    }

    ol {
        list-style-type: none;
        list-style-type: decimal;
        padding-left: 1rem;
        list-style-position: outside;
    }

    ul {
        list-style-type: none;
        list-style-type: disc;
        padding-left: 1rem;
        list-style-position: outside;
    }
}

:host ::ng-deep .mat-mdc-dialog-surface {
    border-radius: 12px;
}